import { template as template_9f671a5680d44e16b39d0edc58252ace } from "@ember/template-compiler";
const FKControlMenuContainer = template_9f671a5680d44e16b39d0edc58252ace(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
