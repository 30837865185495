import { template as template_cac8b2137c1342b3b20ac3d7485a7907 } from "@ember/template-compiler";
const SidebarSectionMessage = template_cac8b2137c1342b3b20ac3d7485a7907(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
